

/*------------------------------------------
        Hero home2 Start Here
-------------------------------------------*/

.hero5-main{
    position: relative;
    background-image: url(/assets/img/hero-bg-img2.png);
    background-position: center;
    background-size: cover;
    overflow: hidden;
    .container-fluid{
        .navbar-main-2{
            .wrapper{
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 32px 0;
                position: relative;
                z-index: 10;
                @include breakpoint($secreen-max-lg){
                    padding: 24px 0;
                }
                .logo-otr{
                    .logo-a{
                        .logo-img{
                            width: 134px;
                            @include breakpoint($secreen-xs){
                                width: 110px;
                            }
                        }
                    }
                }
                .navigation-otr{
                    margin: 0 auto;
                    @include breakpoint($secreen-max-lg){
                        display: none;
                    }
                    .navigation-inr{
                        display: flex;
                        align-items: center;
                        .navigation-li{
                            position: relative;
                            &:not(:last-child){
                                margin-right: 40px;
                            }
                            &:hover{
                                .drop-down-ul{
                                    visibility: visible;
                                    opacity: 1;
                                    top: 50px;
                                }
                            }
                            .nav-a{
                                color: $White-color;
                                font-family: $font-family-Poppins-Medium;
                                line-height: 50px;
                                transition: .2s;
                                cursor: pointer;
                                &:hover{
                                    color: $Primary-color;
                                }
                            }
                            .dots-menu{
                                letter-spacing: 2px;
                                position: relative;
                                font-family: $font-family-Poppins-Bold;
                                top: -2px;
                            }
                            .drop-down-ul{
                                position: absolute;
                                left: -24px;
                                top: 70px;
                                width: 230px;
                                border-radius: 24px;
                                background-color:  $Bg-Dark-Mode;
                                backdrop-filter: blur(25px);
                                padding: 16px 24px 20px 24px;
                                opacity: 0;
                                visibility: hidden;
                                transition: .3s;
                                z-index: 10;
                                .drop-down-li{
                                    position: relative;
                                    &:not(:last-child){
                                        margin-bottom: 12px;
                                    }
                                    &:hover{
                                        .drop-down-ul-other{
                                            opacity: 1;
                                            visibility: visible;
                                        }
                                    }
                                    .drop-down-a{
                                        color: $White-color;
                                        display: inline;
                                        font-family: $font-family-Poppins-Medium;
                                        transition: .2s;
                                        cursor: pointer;
                                        &:hover{
                                            color: $Primary-color;
                                        }
                                    }
                                    .drop-down-ul-other{
                                        position: absolute;
                                        left: 180px;
                                        top: -24px;
                                        width: 230px;
                                        border-radius: 24px;
                                        background-color:  $Bg-Dark-Mode;
                                        backdrop-filter: blur(25px);
                                        padding: 16px 24px 20px 24px;
                                        opacity: 0;
                                        visibility: hidden;
                                        transition: .3s;
                                        z-index: 10;
                                        .drop-down-li-other{
                                            position: relative;
                                            &:not(:last-child){
                                                margin-bottom: 12px;
                                            }
                                            .drop-down-a-other{
                                                color: $White-color;
                                                font-family: $font-family-Poppins-Medium;
                                                display: inline;
                                                transition: .2s;
                                                &:hover{
                                                    color: $Primary-color;
                                                }
                                            }
                                        }       
                                    }
                                }
                            }
                        }
                    }
                }
                .action-nav{
                    display: flex;
                    align-items: center;
                    .search-main{
                        position: relative;
                        width: 280px;
                        @include breakpoint($secreen-max-xlg){
                            width: 200px;
                        }
                        @include breakpoint($secreen-max-lg){
                            display: none;
                        }
                        .input{
                            width: 280px;
                            @include breakpoint($secreen-max-xlg){
                                width: 200px;
                            }
                        }
                        .search-icon{
                            position: absolute;
                            top: 50%;
                            right: 24px;
                            color: $White-color;
                            transform: translateY(-50%);
                            font-size: 16px;
                        }
                    }
                    .action{
                        @include breakpoint($secreen-max-lg){
                            display: none;
                        }
                    }
                    .notification-main{
                        position: relative;
                        @include breakpoint($secreen-max-md){
                            display: block !important;
                        }
                        @include breakpoint($secreen-max-sm){
                            position: inherit;
                        }
                        .icon-notification-otr{
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            position: relative;
                            width: 56px;
                            height: 56px;
                            border-radius: 100%;
                            background-color: $White-Bg-1;
                            @include breakpoint($secreen-xs){
                                width: 40px;
                                height: 40px;
                            }
                            .notification-icon{
                                color: $White-color;
                                font-size: 24px;
                                cursor: pointer;
                                @include breakpoint($secreen-xs){
                                    font-size: 18px;
                                }
                            }
                            .dot{
                                width: 8px;
                                height: 8px;
                                border-radius: 50px;
                                background-color: $Primary-color;
                                position: absolute;
                                top: 4px;
                                right: 4px;
                            }
                        }
                        .notification-drop{
                            position: absolute;
                            background-color:  $Bg-Dark-Mode;
                            backdrop-filter: blur(25px);
                            width: 400px;
                            border-radius: 24px;
                            padding: 24px 28px 32px 28px;
                            top: 100px;
                            right: 0;
                            z-index: 10;
                            opacity: 0;
                            visibility: hidden;
                            transition: .3s;
                            @include breakpoint($secreen-max-sm){
                                width: 100%;
                                padding: 24px 15px 32px 15px;
                            }
                            .Heading-otr{
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                margin: 0 0 24px 0;
                                .heading{
                                    color: $White-color;
                                }
                                .linkk{
                                    color: $Primary-color;
                                }
                            }
                            .notification-ul{
                                .notification-li{
                                    &:not(:last-child){
                                        margin: 0 0 16px 0;
                                    }
                                    .notification-a{
                                        display: flex;
                                        align-items: center;
                                        .img-otr{
                                            width: 70px;
                                            height: 70px;
                                            margin: 0 16px 0 0;
                                            .img{
                                                height: 100%;
                                                border-radius: 6px;
                                            }
                                        }
                                        .content-otr{
                                            .name{
                                                color: $White-color;
                                                margin: 0 0 6px 0;
                                            }
                                            .price{
                                                color: $Shade-1;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        .notification-drop-open{
                            top: 60px;
                            opacity: 1;
                            visibility: visible;
                            @include breakpoint($secreen-max-sm){
                                top: 80px;
                            }
                        }
                    }
                    .right-space{
                        @include breakpoint($secreen-max-md){
                            display: none;
                        }
                        &:not(:last-child){
                            margin-right: 24px;
                            @include breakpoint($secreen-xs){
                                margin-right: 0;
                            }
                        }
                    }
                    .profile-nav-main{
                        position: relative;
                        @include breakpoint($secreen-max-lg){
                            margin-right: 24px;
                        }
                        @include breakpoint($secreen-max-sm){
                            position: inherit;
                        }
                        @include breakpoint($secreen-xs){
                            margin: 0 12px;
                        }
                        .profile-nav{
                            display: flex;
                            align-items: center;
                            cursor: pointer;
                            background-color: $White-Bg-1;
                            border-radius: 12px;
                            height: 56px;
                            @include breakpoint($secreen-xs){
                                height: 40px;
                            }
                            .img-otr{
                                .nav-prof-img{
                                    height: 56px;
                                    width: 56px;
                                    border-radius: 12px 0 0 12px;
                                    @include breakpoint($secreen-xs){
                                        width: 40px;
                                        height: 40px;
                                        border-radius: 12px;
                                    }
                                }
                            }
                            .desc{
                                color: $White-color;
                                font-family: $font-family-Poppins-Medium;
                                padding: 0 16px;
                                @include breakpoint($secreen-xs){
                                    display: none;
                                }
                            }
                        }
                        .profile-pop-otr{
                            position: absolute;
                            background-color:  $Bg-Dark-Mode;
                            backdrop-filter: blur(25px);
                            width: 300px;
                            border-radius: 24px;
                            padding: 24px 28px 32px 28px;
                            top: 100px;
                            right: 0;
                            z-index: 10;
                            opacity: 0;
                            visibility: hidden;
                            transition: .3s;
                            @include breakpoint($secreen-max-sm){
                                width: 100%;
                                padding: 24px 15px;
                            }
                            .copy-icon-otr{
                                display: flex;
                                align-items: center;
                                cursor: pointer;
                                .text{
                                    color: $White-color;
                                }
                                .copy-icon{
                                    font-size: 24px;
                                    color: $White-color;
                                }
                            }
                            .balance-otr{
                                display: flex;
                                align-items: center;
                                margin: 16px 0;
                                .img-etherem{
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    background-color: $White-color;
                                    width: 56px;
                                    height: 56px;
                                    border-radius: 100%;
                                    .etherem{
                                        height: 32px;
                                    }
                                }
                                .balance{
                                    margin-left: 16px;
                                    .text{
                                        color: $Shade-1;
                                    }
                                    .price{
                                        color: $White-color;
                                    }
                                }
                            }
                            .link-profile-ul{
                                .link-profile-li{
                                    &:not(:last-child){
                                        margin-bottom: 12px;
                                    }
                                    .link-profile-a{
                                        display: inline;
                                        font-family: $font-family-Poppins-Medium;
                                        color: $White-color;
                                        transition: .3s;
                                        &:hover{
                                            color: $Primary-color;
                                        }
                                    }
                                }
                            }
                        }
                        .profile-pop-open{
                            top: 60px;
                            opacity: 1;
                            visibility: visible;
                            @include breakpoint($secreen-max-sm){
                                top: 80px;
                            }
                        }
                    }
                    .burger-icon-otr{
                        display: none;
                        align-items: center;
                        justify-content: center;
                        width: 56px;
                        height: 56px;
                        border-radius: 100%;
                        background-color: $White-Bg-1;
                        cursor: pointer;
                        @include breakpoint($secreen-max-lg){
                            display: flex;
                        }
                        @include breakpoint($secreen-xs){
                            width: 40px;
                            height: 40px;
                        }
                        .burger-icon{
                            font-size: 24px;
                            color: $White-color;
                            @include breakpoint($secreen-xs){
                                font-size: 18px;
                            }
                        }
                    }
                }
            }
        }
        .row-custom{
            align-items: center;
            padding: 96px 100px 128px 100px;
            @include breakpoint($secreen-max-xlg){
                padding: 96px 50px 128px 50px;
            }
            @include breakpoint($secreen-max-md){
                padding: 96px 0 128px 0;
            }
            .col-img-otr{
                @include breakpoint($secreen-max-xl){
                    width: 60%;
                    margin: 0 auto;
                }
                @include breakpoint($secreen-max-md){
                    width: 80%;
                }
                @include breakpoint($secreen-max-sm){
                    width: 100%;
                }
                .col-img-inr{
                    border-radius: 500px 500px 24px 24px;
                    background-color: $White-Bg-1;
                    padding: 36px 36px 34px 36px;
                    margin-right: 50px;
                    @include breakpoint($secreen-max-lg){
                        margin-right: 0;
                    }
                    @include breakpoint($secreen-xs){
                        padding: 28px 28px 24px 28px;
                    }
                    .img-otr{
                        position: relative;
                        overflow: hidden;
                        border-radius: 16px;
                        .hero-img{
                            width: 100%;
                            border-radius: 500px 500px 16px 16px;
                        }
                        .bid-main{
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            padding: 16px 0px 16px 20px;
                            border-radius: 8px;
                            background-color: $White-bg-2;
                            position: absolute;
                            left: 50%;
                            transform: translateX(-50%);
                            bottom: 36px;
                            width: calc(100% - 72px);
                            margin: 0 auto;
                            @include breakpoint($secreen-xs){
                                display: none;
                            }
                            .left-bid{
                                position: relative;
                                z-index: 1;
                                .bid-desc{
                                    color: $White-color;
                                }
                                .ETH{
                                    color: $Primary-color;
                                }
                            }
                            .right-bid{
                                position: relative;
                                z-index: 1;
                                width: 125px;
                                .end-desc{
                                    color: $White-color;
                                }
                                .timer{
                                    color: $Primary-color;
                                }
                            }
                            .blur-effect{
                                backdrop-filter: blur(25px);
                            }
                        }
                        .time-otr{
                            display: none;
                            position: absolute;
                            left: 0;
                            bottom: 0;
                            background-color: $White-bg-2;
                            z-index: 10;
                            border-radius: 14px;
                            padding: 6px 16px;
                            backdrop-filter: blur(25px);
                            @include breakpoint($secreen-xs){
                                display: block;
                            }
                            .timer{
                                color: $White-color;
                            }
                        }
                        .heart-main{
                            display: none;
                            position: absolute;
                            top: 150px;
                            right: 24px;
                            @include breakpoint($secreen-xs){
                                display: block;
                            }
                            @include breakpoint($secreen-xxs){
                                top: 100px;
                            }
                            .heart-icon-otr{
                                width: 52px;
                                height: 52px;
                                border-radius: 100%;
                                display: flex;
                                background-color: $White-Bg-1;
                                backdrop-filter: blur(25px);
                                position: relative;
                                cursor: pointer;
                                .heart-icon{
                                    position: absolute;
                                    left: 50%;
                                    top: 50%;
                                    transform: translate(-50%, -50%);
                                    font-size: 24px;
                                    color: $Primary-color;
                                }
                                .ri-heart-fill{
                                    color: red;
                                }
                            }
                        }
                    }
                    .profile-main{
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin: 24px 0 0 0;
                        .profile-inr{
                            display: flex;
                            .img-otr{
                                position: relative;
                                overflow: visible ;
                                transition: .2s;
                                .img-inr{
                                    .avatar-img{
                                        height: 56px;
                                        width: 56px;
                                        border-radius: 100%;
                                        border: 2px solid $White-color;
                                        @include breakpoint($secreen-xs){
                                            width: 40px;
                                            height: 40px;
                                        }
                                    }
                                }
                                .check-icon-otr{
                                    width: 20px;
                                    height: 20px;
                                    border-radius: 100px;
                                    background-color: $Primary-color;
                                    border: 2px solid $White-color;
                                    position: absolute;
                                    display: flex;
                                    right: 0;
                                    bottom: 0;
                                    @include breakpoint($secreen-xs){
                                        width: 16px;
                                        height: 16px;
                                    }
                                    .check-icon{
                                        position: relative;
                                        top: 40%;
                                        left: 50%;
                                        transform: translate(-50%, -50%);
                                        color: $White-color;
                                        font-size: 14px;
                                        @include breakpoint($secreen-xs){
                                            top: 30%;
                                            font-size: 12px;
                                        }
                                    }
                                }
                            }
                            .img-1,.img-2,.img-3{
                                position: relative;
                                &:hover{
                                    z-index: 1;
                                    transform: translateY(-3px);
                                    .hover{
                                        opacity: 1;
                                        visibility: visible;
                                    }
                                }
                                .hover{
                                    position: absolute;
                                    text-align: center;
                                    width: 260px;
                                    background-color: $Primary-shade;
                                    border: 1px solid $Primary-color;
                                    border-radius: 8px;
                                    left: 0;
                                    top: -60px;
                                    transition: .3s;
                                    opacity: 0;
                                    visibility: hidden;
                                    @include breakpoint($secreen-max-md){
                                        display: none;
                                    }
                                    .text{
                                        color: $White-color;
                                        padding: 6px 16px;
                                    }
                                }
                            }
                            .img-2{
                                margin-left: -25px;
                                @include breakpoint($secreen-xs){
                                    margin-left: -15px;
                                }
                            }
                            .img-3{
                                margin-left: -25px;
                                @include breakpoint($secreen-xs){
                                    margin-left: -15px;
                                }
                            }
                        }
                        .heart-main{
                            display: flex;
                            align-items: center;
                            @include breakpoint($secreen-xs){
                                display: none;
                            }
                            .heart-icon-otr{
                                width: 52px;
                                height: 52px;
                                border-radius: 100%;
                                display: flex;
                                background-color: $White-Bg-1;
                                position: relative;
                                cursor: pointer;
                                .heart-icon{
                                    position: absolute;
                                    left: 50%;
                                    top: 50%;
                                    transform: translate(-50%, -50%);
                                    font-size: 24px;
                                    color: $Primary-color;
                                }
                                .ri-heart-fill{
                                    color: red;
                                }
                            }
                            .likes{
                                color: $Shade-1;
                                margin-left: 16px;
                            }
                        }
                        .left-bid{
                            display: none;
                            @include breakpoint($secreen-xs){
                                display: block;
                            }
                            .bid-desc{
                                color: $White-color;
                            }
                            .ETH{
                                color: $Primary-color;
                            }
                        }
                    }
                }
            }
            .col-content-otr{
                @include breakpoint($secreen-max-xl){
                    width: 100%;
                }
                .col-content-inr{
                    padding-left: 50px;
                    @include breakpoint($secreen-max-xlg){
                        padding: 0;
                    }
                    @include breakpoint($secreen-max-xl){
                        padding: 72px 0 0 0;
                        text-align: center;
                    }
                    @include breakpoint($secreen-max-sm){
                        text-align: left;
                    }
                    .heading{
                        color: $White-color;
                        .text-color{
                            color: $Primary-color;
                        }
                    }
                    .desc{
                        color: $Shade-1;
                        padding: 20px 0 40px 0;
                    }
                    .action{
                        display: flex;
                        @include breakpoint($secreen-max-xl){
                            text-align: center;
                            justify-content: center;
                        }
                        @include breakpoint($secreen-max-sm){
                            justify-content: flex-start;
                        }
                        @include breakpoint($secreen-xxs){
                            flex-direction: column;
                        }
                        .left-btn{
                            margin-right: 24px;
                            @include breakpoint($secreen-xxs){
                                margin: 0 0 24px 0;
                            }
                        }
                    }
                    .staticstics{
                        display: flex;
                        margin: 40px 0 0 0;
                        @include breakpoint($secreen-max-xl){
                            text-align: center;
                            justify-content: center;
                        }
                        @include breakpoint($secreen-max-sm){
                            justify-content: flex-start;
                            text-align: left;
                        }
                        @include breakpoint($secreen-xxs){
                            flex-direction: column;
                        }
                        .staticstics-box{
                            padding: 24px 28px 30px 28px;
                            border-radius: 16px;
                            background-color: $White-Bg-1;
                            &:not(:last-child){
                                margin-right: 24px;
                                @include breakpoint($secreen-xxs){
                                    margin: 0 0 24px 0;
                                }
                            }
                            .static-head{
                                color: $White-color;
                            }
                            .static-desc{
                                color: $White-color;
                            }
                        }
                    }
                }
            }
        }
    }
    .lines{
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        z-index: -1;
        @include breakpoint($secreen-max-xl){
            display: none;
        }
    }
}

/*------------------------------------------
        Hero home2 End Here
-------------------------------------------*/