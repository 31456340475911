
/*====================================
        Theme Color's
====================================*/

$Shade-1: #B7B7B7;
$Shade-2: #878787;
$White-Bg-1: rgba(255, 255, 255, 0.05);
$White-bg-2: rgba(255, 255, 255, 0.2);
$White-color: #FFFFFF;
$Dark-bg: #091830;
$Dark-black: #000000;
$Bg-Dark-Mode: rgba(0, 0, 0, 0.8);
$Primary-color: #019DEA;
$Primary-shade: #222F45;

/*====================================
        Theme Fonts Size's
====================================*/

$font-size-h1: 56px;
$font-size-h2: 40px;
$font-size-h3: 32px;
$font-size-h4: 24px;
$font-size-h5: 20px;
$font-size-L: 20px;
$font-size-M: 18px;
$font-size-S: 16px;

/*====================================
        Theme Line Height's
====================================*/

$line-height-h1: 90px;
$line-height-h2: 72px;
$line-height-h3: 48px;
$line-height-h4: 40px;
$line-height-h5: 36px;
$line-height-L: 36px;
$line-height-M: 32px;
$line-height-S: 28px;

/*====================================
        Theme Font Family's
====================================*/

$font-family-Montserrat-Bold: 'Montserrat-Bold';
$font-family-Poppins-Bold: 'Poppins-Bold';
$font-family-Poppins-Medium: 'Poppins-Medium';
$font-family-Poppins-Regular: 'Poppins-Regular';




