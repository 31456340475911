

/*------------------------------------------
        Explore Artwork Start Here
-------------------------------------------*/

.explore-main{
    margin: 128px 0;
    overflow: hidden;
    .container-fluid{
        .wrapper{
            margin: 0 0 72px 0;
            .teb-main{
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex-wrap: wrap;
                .tab-otr{
                    display: flex;
                    align-items: center;
                    @include breakpoint($secreen-xxs){
                        flex-direction: column;
                        align-items: flex-start;
                        width: 100%;
                    }
                    .heading{
                        color: $White-color;
                        margin: 0 24px 0 0;
                        @include breakpoint($secreen-xxs){
                            margin: 0 0 24px 0;
                        }
                    }
                    .tab-inr{
                        position: relative;
                        @include breakpoint($secreen-xxs){
                            width: 100%;
                        }
                        .btn-drop{
                            display: flex;
                            align-items: center;
                            background-color: $White-Bg-1;
                            border: 2px solid $Primary-color;
                            border-radius: 12px;
                            padding: 6px 16px;
                            cursor: pointer;
                            @include breakpoint($secreen-xxs){
                                position: relative;
                                width: 100%;
                            }
                            .btn-drop-inr{
                                color: $White-color;
                            }
                            .arrow-down{
                                font-size: 18px;
                                color: $White-color;
                                margin: 0 0 0 8px;
                                @include breakpoint($secreen-xxs){
                                    position: absolute;
                                    right: 16px;
                                    margin: 0;
                                }
                            }
                            .change-text{
                                color: $White-color;
                            }
                        }
                        .tabs-ul{
                            position: absolute;
                            left: 0;
                            top: 80px;
                            width: 200px;
                            border-radius: 24px;
                            background-color: $Primary-shade;
                            padding: 16px 24px 20px 24px;
                            opacity: 0;
                            visibility: hidden;
                            transition: .3s;
                            z-index: 10;
                            .tab-link{
                                cursor: pointer;
                                &:not(:last-child){
                                    margin-bottom: 12px;
                                }
                                .tab-p{
                                    color: $White-color;
                                }
                            }
                            .active{
                                .tab-p{
                                    color: $Primary-color;
                                }
                            }
                        }
                        .tab-drop-open{
                            opacity: 1;
                            visibility: visible;
                            top: 50px;
                        }
                    }
                }
                .slect-main{
                    display: flex;
                    align-items: center;
                    @include breakpoint($secreen-max-sm){
                        margin-top: 24px;
                    }
                    @include breakpoint($secreen-xxs){
                        width: 100%;
                        flex-direction: column;
                    }
                    .select {
                        cursor: pointer;
                        display: inline-block;
                        position: relative;
                        @include breakpoint($secreen-xxs){
                            width: 100%;
                        }
                        &:not(:last-child){
                            margin-right: 24px;
                            @include breakpoint($secreen-xxs){
                                margin: 0 0 24px 0;
                            }
                        }
                        .select-hidden {
                            display: none;
                            visibility: hidden;
                            padding-right: 10px;
                        }
                        .select-styled {
                            background-color: $White-Bg-1;
                            color: $White-color;
                            padding: 6px 40px 6px 16px;
                            border-radius: 12px;
                            border: 2px solid $Primary-color;
                            font-size: $font-size-S;
                            line-height: $line-height-S;
                            font-family: $font-family-Poppins-Medium;
                            &:after {
                                content:"";
                                width: 12px;
                                height: 10px;
                                position: absolute;
                                background-image: url(/assets/img/arrow-drop-down.svg);
                                background-repeat: no-repeat;
                                background-position: center;
                                top: 16px;
                                right: 16px;
                            }
                            &:active, &.active {
                                &:after {
                                    top: 16px;
                                    border-color: transparent transparent $White-color transparent;
                                }
                            }
                        }
                        .select-options {
                            display: none; 
                            position: absolute;
                            top: 130%;
                            right: 0;
                            width: 200px;
                            z-index: 999;
                            background-color: $Primary-shade;
                            border-radius: 12px;
                            padding: 16px 0;
                            box-shadow: none;
                            @include breakpoint($secreen-xs){
                                right: inherit;
                                left: 0;
                            }
                            li {
                                font-size: $font-size-S;
                                line-height: $line-height-S;
                                font-family: $font-family-Poppins-Medium;
                                color: $White-color;
                                border-radius: 12px;
                                padding: 0 24px 0 24px;
                                transition: .3s;
                                &:not(:last-child){
                                    margin: 0 0 8px 0;
                                }
                                &:hover {
                                    color: $Primary-color;
                                    background: transparent;
                                    border-radius: 0;
                                }
                                &[rel="hide"] {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
        }
        .row-custom-main{
            .tab-content{
                display: none;
                opacity: 0;
                transform: translateY(0px);
                animation: fadeIn 0.3s ease 1 forwards;
                .row-custom-inr{
                    .col-otr{
                        @include breakpoint($secreen-max-xlg){
                            width: 33.333%;
                        }
                        @include breakpoint($secreen-max-md){
                            width: 50%;
                        }
                        @include breakpoint($secreen-custom){
                            width: 100%;
                        }
                        .col-inr{
                            background-color: $White-Bg-1;
                            border-radius: 24px;
                            padding: 28px 24px 32px 24px;
                            .avatar-main{
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                margin: 0 0 24px 0;
                                .profile-inr{
                                    display: flex;
                                    .img-otr{
                                        position: relative;
                                        transition: .2s;
                                        .img-inr{
                                            .avatar-img{
                                                height: 40px;
                                                width: 40px;
                                                border-radius: 100%;
                                                border: 2px solid $White-color;
                                            }
                                        }
                                        .check-icon-otr{
                                            width: 16px;
                                            height: 16px;
                                            border-radius: 100px;
                                            background-color: $Primary-color;
                                            border: 1px solid $White-color;
                                            position: absolute;
                                            display: flex;
                                            right: 0;
                                            bottom: 0;
                                            .check-icon{
                                                position: relative;
                                                top: 43%;
                                                left: 50%;
                                                transform: translate(-50%, -50%);
                                                color: $White-color;
                                                font-size: 12px;
                                            }
                                        }
                                    }
                                    .img-1,.img-2,.img-3{
                                        &:hover{
                                            z-index: 1;
                                            transform: translateY(-3px);
                                            .hover{
                                                opacity: 1;
                                                visibility: visible;
                                            }
                                        }
                                        .hover{
                                            position: absolute;
                                            text-align: center;
                                            width: 260px;
                                            background-color: $Primary-shade;
                                            border: 1px solid $Primary-color;
                                            border-radius: 8px;
                                            left: 0;
                                            top: -60px;
                                            transition: .3s;
                                            opacity: 0;
                                            visibility: hidden;
                                            @include breakpoint($secreen-max-md){
                                                display: none;
                                            }
                                            .text{
                                                color: $White-color;
                                                padding: 6px 16px;
                                            }
                                        }
                                    }
                                    .img-2{
                                        margin-left: -20px;
                                    }
                                    .img-3{
                                        margin-left: -20px;
                                    }
                                }
                                .list-icon-otr{
                                    position: relative;
                                    cursor: pointer;
                                    .icon{
                                        
                                    }
                                    .drop-artwork{
                                        position: absolute;
                                        background-color: $Primary-shade;
                                        border-radius: 8px;
                                        padding: 10px 0 12px 16px;
                                        width: 110px;
                                        right: 8;
                                        top: 0;
                                        z-index: 10;
                                        transition: .3s;
                                        display: none;
                                        .drop-ul{
                                            .drop-li{
                                                color: $White-color;
                                                transition: .3s;
                                                &:hover{
                                                    color: $Primary-color;
                                                }
                                                &:not(:last-child){
                                                    margin: 0 0 6px 0;
                                                }
                                            }
                                        }
                                    }
                                    .tab-content{
                                        display: none;
                                    }
                                    .active{
                                        display: block;
                                    }
                                }
                            }
                            .cover-img-otr{
                                position: relative;
                                .cover-img{
                                    width: 100%;
                                    border-radius: 16px;
                                }
                                .time-otr{
                                    position: absolute;
                                    left: 0;
                                    bottom: 0;
                                    background-color: $White-bg-2;
                                    z-index: 10;
                                    border-radius: 14px;
                                    width: 124px;
                                    height: 40px;
                                    backdrop-filter: blur(25px);
                                    .timer{
                                        position: absolute;
                                        left: 65%;
                                        top: 50%;
                                        transform: translate(-50%, -50%);
                                        width: 90%;
                                        color: $White-color;
                                    }
                                }
                                .heart-icon-otr2{
                                    position: absolute;
                                    width: 52px;
                                    height: 52px;
                                    border-radius: 100%;
                                    display: flex;
                                    background-color: $White-bg-2;
                                    cursor: pointer;
                                    top: 24px;
                                    right: 24px;
                                    backdrop-filter: blur(25px);
                                    .heart-icon2{
                                        position: absolute;
                                        left: 50%;
                                        top: 50%;
                                        transform: translate(-50%, -50%);
                                        font-size: 24px;
                                        color: $White-color;
                                    }
                                    .ri-heart-fill{
                                        color: red;
                                    }
                                }
                            }
                            .art-name{
                                display: inline-flex;
                                color: $White-color;
                                margin: 18px 0;
                                transition: .3s;
                                &:hover{
                                    color: $Primary-color;
                                }
                            }
                            .bid-main{
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                background-color: $White-Bg-1;
                                padding: 16px 20px;
                                border-radius: 8px;
                                .bid{
                                    color: $Shade-1;
                                }
                                .Price{
                                    color: $White-color;
                                }
                            }
                        }
                        .box-1,.box-2,.box-3,.box-4{
                            margin: 0 0 24px 0;
                        }
                        .box-1,.box-2,.box-3,.box-4,.box-5,.box-6{
                            @include breakpoint($secreen-max-md){
                                margin: 0 0 24px 0;
                            }
                        }
                        .box-7{
                            @include breakpoint($secreen-custom){
                                margin: 0 0 24px 0;
                            }
                        }
                    }
                }
            }
            .active{
                display: block;
                opacity: 1;
            }
            .col-btn-otr{
                .col-btn-inr{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: 48px 0 0 0;
                }
            }
        }
    }
}

/*------------------------------------------
        Explore Artwork End Here
-------------------------------------------*/