

/*------------------------------------------
            Footer Start Here
-------------------------------------------*/

.footer-main{
    position: relative;
    padding: 128px 0;
    background: linear-gradient(252.07deg, rgba(1, 156, 234, 0.090) 0%, rgba(255, 255, 255, 0.090) 100%);
    &::before{
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background-image: url(/assets/img/footer-bg-img.png);
        top: 0;
        left: 0;
        opacity: .6;
    }
    .container-fluid{
        .row-custom{
            position: relative;
            z-index: 2;
            .col-logo-otr{
                .col-logo-inr{
                    @include breakpoint($secreen-max-sm){
                        padding: 0 0 48px 0;
                    }
                    .logo-otr{
                        margin: 0 0 8px 0;
                        .logo-inr{
                            .img-logo{
                                width: 140px;
                            }
                        }
                    }
                    .social-icon{
                        .icon-ul{
                            display: flex;
                            align-items: center;
                            flex-wrap: wrap;
                            .icon-li{
                                margin-top: 16px;
                                &:not(:last-child){
                                    margin-right: 16px;
                                }
                                .icon-a{
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    width: 52px;
                                    height: 52px;
                                    border-radius: 100%;
                                    background-color: $White-Bg-1;
                                    transition: .3s;
                                    &:hover{
                                        background-color: $Primary-color;
                                    }
                                    .icon{

                                    }
                                }
                            }
                        }
                    }
                }
            }
            .col-explore-otr{
                .col-explore-inr{
                    padding: 0 0 0 100px;
                    @include breakpoint($secreen-max-xlg){
                        padding: 0 0 0 60px;
                    }
                    @include breakpoint($secreen-max-xl){
                        padding: 0 0 0 30px;
                    }
                    @include breakpoint($secreen-max-md){
                        padding: 0 0 48px 0;
                    }
                    .explore-otr{
                        margin: 0 0 36px 0;
                        @include breakpoint($secreen-max-sm){
                            margin: 0 0 48px 0;
                        }
                        .heading{
                            color: $White-color;
                            margin: 0 0 12px 0;
                        }
                        .explore-ul{
                            .explore-li{
                                &:not(:last-child){
                                    margin: 0 0 8px 0;
                                }
                                .explore-a{
                                    color: $Shade-1;
                                    display: inline;
                                    transition: .3s;
                                    &:hover{
                                        color: $Primary-color;
                                    }
                                }
                            }
                        }
                    }
                    .community-otr{
                        .heading{
                            color: $White-color;
                            margin: 0 0 12px 0;
                        }
                        .community-ul{
                            .community-li{
                                &:not(:last-child){
                                    margin: 0 0 8px 0;
                                }
                                .community-a{
                                    color: $Shade-1;
                                    display: inline;
                                    transition: .3s;
                                    &:hover{
                                        color: $Primary-color;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .col-navigation-otr{
                .col-navigation-inr{
                    padding: 0 0 0 40px;
                    @include breakpoint($secreen-max-xlg){
                        padding: 0 0 0 20px;
                    }
                    @include breakpoint($secreen-max-xl){
                        padding: 0;
                    }
                    @include breakpoint($secreen-max-sm){
                        padding: 0 0 48px 0;
                    }
                    .navigation-otr{
                        .heading{
                            color: $White-color;
                            margin: 0 0 12px 0;
                        }
                        .navigation-ul{
                            .navigation-li{
                                &:not(:last-child){
                                    margin: 0 0 8px 0;
                                }
                                .navigation-a{
                                    color: $Shade-1;
                                    display: inline;
                                    transition: .3s;
                                    &:hover{
                                        color: $Primary-color;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .col-subscribe-otr{
                .col-subscribe-inr{
                    padding: 0 0 0 150px;
                    @include breakpoint($secreen-max-xlg){
                        padding: 0 0 0 100px;
                    }
                    @include breakpoint($secreen-max-xl){
                        padding: 0 0 0 70px;
                    }
                    @include breakpoint($secreen-max-md){
                        padding: 0;
                    }
                    .heading{
                        color: $White-color;
                    }
                    .email-otr{
                        margin: 24px 0 32px 0;
                        input{
                            width: 100%;
                        }
                    }
                    .action{
                        display: flex;
                    }
                }
            }
        }
    }
}
.copyright-main{
    .container-fluid{
        .wrapper{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 32px 0;
            @include breakpoint($secreen-max-sm){
                flex-direction: column;
                text-align: center;
            }
            .copy-right{
                color: $White-color;
                @include breakpoint($secreen-max-sm){
                    margin: 0 0 16px 0;
                }
                .linkk{
                    display: inline;
                    color: $Primary-color;
                }
            }
            .linkk-otr{
                .linkk-inr{
                    color: $White-color;
                    .linkk-page{
                        display: inline;
                        color: $White-color;
                        transition: .3s;
                        &:hover{
                            color: $Primary-color;
                        }
                    }
                }
            }
        }
    }
}

/*------------------------------------------
            Footer End Here
-------------------------------------------*/